/* eslint-disable import/prefer-default-export */
import React from "react";
import { RecoilRoot } from "recoil";
import { Location, globalHistory } from "@reach/router";
import { QueryParamProvider } from "use-query-params";

import IdleTimer from "./src/components/IdleTimer/IdleTimer";

export const wrapRootElement = ({ element }) => (
    // eslint-disable-next-line react/jsx-filename-extension
    <React.StrictMode>
      <RecoilRoot>
        <Location>
          {({ location }) => (
            <QueryParamProvider location={location} reachHistory={globalHistory}>
              <IdleTimer />
              {element}
            </QueryParamProvider>
          )}
        </Location>
      </RecoilRoot>
    </React.StrictMode>
  )