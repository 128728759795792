// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-all-products-jsx": () => import("./../../../src/pages/all-products.jsx" /* webpackChunkName: "component---src-pages-all-products-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-collarlab-custom-jsx": () => import("./../../../src/pages/collarlab-custom.jsx" /* webpackChunkName: "component---src-pages-collarlab-custom-jsx" */),
  "component---src-pages-collarlab-intro-jsx": () => import("./../../../src/pages/collarlab-intro.jsx" /* webpackChunkName: "component---src-pages-collarlab-intro-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-qr-jsx": () => import("./../../../src/pages/qr.jsx" /* webpackChunkName: "component---src-pages-qr-jsx" */),
  "component---src-pages-spid-custom-jsx": () => import("./../../../src/pages/spid-custom.jsx" /* webpackChunkName: "component---src-pages-spid-custom-jsx" */),
  "component---src-pages-spid-grid-jsx": () => import("./../../../src/pages/spid-grid.jsx" /* webpackChunkName: "component---src-pages-spid-grid-jsx" */),
  "component---src-pages-testing-jsx": () => import("./../../../src/pages/testing.jsx" /* webpackChunkName: "component---src-pages-testing-jsx" */)
}

