import React from "react";
import { useIdleTimer } from 'react-idle-timer'

const IdleTimer = () => {

  const handleOnAction = event => {
    // console.log('user did something', event)
    if (window.parent) {
      window.parent.postMessage(
        JSON.stringify({
          command: 'user_activity',
        }),
        '*',
      );
    }
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onAction: handleOnAction,
    debounce: 500
  })

  return (
    <div />
  )
}

export default IdleTimer;
